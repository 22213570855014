<template>
  <div class="pa-2">
    <v-card>
      <v-card-title class="pb-2">
        <v-row no-gutters>
          <v-col cols="12" md="12">Receive Voucher</v-col>
          <v-col cols="12" md="4">
            <v-row no-gutters>
              <v-col cols="6" md="6" class="px-2">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="290px"
                  v-model="toMeun"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="fromDateValue"
                      label="From Date"
                      prepend-icon="event"
                      hide-details
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker :type="datePickerType" v-model="fromDate" @input="toMeun = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" md="6" class="px-2">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="290px"
                  v-model="fromMeun"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="toDateValue"
                      label="To Date"
                      prepend-icon="event"
                      hide-details
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker :type="datePickerType" v-model="toDate" @input="fromMeun = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" class="px-2">
                <v-radio-group v-model="dateType" row hide-details>
                  <v-radio label="Day" value="date"></v-radio>
                  <v-radio label="Month" value="month"></v-radio>
                  <v-radio label="Year" value="year"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" md="3" class="px-2">
            <v-autocomplete
              item-text="customerName"
              item-value="customerId"
              :items="listCustomer"
              v-model="customerId"
              label="customer"
              hide-details
            />
          </v-col>
          <v-col cols="3" md="3" class="px-2">
            <v-autocomplete
              item-text="customerName"
              item-value="customerId"
              :items="listVoucherType"
              v-model="voucherType"
              label="Voucher Type"
              hide-details
            />
          </v-col>
          <v-col cols="1" class="text-center pa-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" depressed v-on="on" @click="searchVoucherList" small fab>
                  <v-icon>search</v-icon>
                </v-btn>
              </template>
              <span>Search</span>
            </v-tooltip>
          </v-col>
          <v-col cols="1" class="text-left pt-2">
            <!-- <v-chip
              @click:close="unselected(chip)"
              v-for="(chip,i) in selected"
              class="mx-1"
              :key="i"
              close
              small
            >{{chip.name}}</v-chip>-->
            <v-btn
              :disabled="!(selectedVoucher.length > 0)"
              @click="receivedVoucher"
              color="primary"
              depressed
              small
            >{{(selectedVoucher.length == 0)?"":selectedVoucher.length}} Fully Paided</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <!-- <v-data-table
              v-model="selected"
              :headers="headers"
              :items="desserts"
              dense
              fixed-header
              height="70vh"
              hide-default-footer
              item-key="name"
              show-select
              class="elevation-0"
            ></v-data-table>-->
            <v-simple-table fixed-header height="73vh">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th rowspan="2" style="width: 10px;" class="text-center">
                      <v-checkbox v-if="voucherList.length != 0" v-model="checkAllOrZero"></v-checkbox>
                    </th>
                    <th rowspan="2" class="text-center">Date</th>
                    <th rowspan="2" class="text-center">Voucher Id</th>
                    <th rowspan="2" class="text-center">PNR</th>
                    <th rowspan="2" class="text-center">For Person</th>
                    <th colspan="2" class="text-center">Amount</th>
                    <th colspan="2" class="text-center">Discount</th>
                    <th colspan="2" class="text-center">Commission</th>
                    <th colspan="2" class="text-center">Receive</th>
                    <th colspan="2" class="text-center">Balance</th>
                    <th colspan="2" class="text-center">Remark</th>
                  </tr>
                  <tr>
                    <th class="text-center">MMK</th>
                    <th class="text-center">USD</th>
                    <th class="text-center">MMK</th>
                    <th class="text-center">USD</th>
                    <th class="text-center">MMK</th>
                    <th class="text-center">USD</th>
                    <th class="text-center">MMK</th>
                    <th class="text-center">USD</th>
                    <th class="text-center">MMK</th>
                    <th class="text-center">USD</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item,j) in voucherList" :key="j">
                    <td
                      v-if="item.code=='total'"
                      colspan="5"
                      class="text-center"
                    >Total {{item.code}}</td>
                    <td v-else class="text-center">
                      <v-checkbox
                        class="mt-0"
                        hide-details
                        v-model="selectedVoucher"
                        :value="item.code"
                      ></v-checkbox>
                    </td>
                    <td
                      v-if="item.code!='total'"
                      style="width: 90px;"
                      class="text-center"
                    >{{item.date}}</td>
                    <td
                      v-if="item.code!='total'"
                      class="text-center"
                      style="width: 90px;"
                    >{{ item.code }}</td>
                    <td
                      v-if="item.code!='total'"
                      class="text-center overline"
                      style="width: 90px;"
                    >{{ item.pnr }}</td>
                    <td v-if="item.code!='total'" class="text-left" style="width: 250px;">
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">{{ item.forPerson | forPersonFormat }}</span>
                        </template>
                        <span>{{item.forPerson}}</span>
                      </v-tooltip>
                    </td>
                    <td
                      class="text-right"
                      style="width: 90px;"
                    >{{ item.mmkAmount | defaultPaymentFormat}}</td>
                    <td
                      class="text-right"
                      style="width: 70px;"
                    >{{ item.usdAmount | defaultPaymentWithDollarSignFormat }}</td>
                    <td
                      class="text-right"
                      style="width: 90px;"
                    >{{ item.mmkDiscount | defaultPaymentFormat}}</td>
                    <td
                      class="text-right"
                      style="width: 70px;"
                    >{{ item.usdDiscount | defaultPaymentWithDollarSignFormat }}</td>
                    <td
                      class="text-right"
                      style="width: 90px;"
                    >{{ item.mmkCommission | defaultPaymentFormat}}</td>
                    <td
                      class="text-right"
                      style="width: 70px;"
                    >{{ item.usdCommission | defaultPaymentWithDollarSignFormat }}</td>
                    <td
                      class="text-right"
                      style="width: 90px;"
                    >{{ item.mmkReceive | defaultPaymentFormat}}</td>
                    <td
                      class="text-right"
                      style="width: 70px;"
                    >{{ item.usdReceive | defaultPaymentWithDollarSignFormat }}</td>
                    <td
                      class="text-right"
                      style="width: 90px;"
                    >{{ item.mmkBalance | defaultPaymentFormat}}</td>
                    <td
                      class="text-right"
                      style="width: 70px;"
                    >{{ item.usdBalance | defaultPaymentWithDollarSignFormat }}</td>
                    <td class="text-left" style="width: 100px;">{{ item.remark }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import voucherService from "../service/VoucherService";
import customerService from "../service/CustomerService";
export default {
  data() {
    return {
      toMeun: false,
      fromMeun: false,
      checkAllOrZero: false,
      dateType: "date",
      datePickerType: "date",
      customerId: 0,
      toDate: new Date().toISOString().substr(0, 10),
      fromDate: new Date().toISOString().substr(0, 10),
      pnr: null,
      singleSelect: false,
      voucherType: "Voucher",
      selected: [],
      listCustomer: [],
      listVoucherType: ["Voucher", "Change Voucher"],
      selectedVoucher: [],
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "left",
          sortable: false,
          value: "name"
        },
        { text: "Calories", value: "calories" },
        { text: "Fat (g)", value: "fat" },
        { text: "Carbs (g)", value: "carbs" },
        { text: "Protein (g)", value: "protein" },
        { text: "Iron (%)", value: "iron" }
      ],
      voucherList: []
    };
  },
  mounted: function() {
    var i = 0;
    customerService.getCustomer().then(data => {
      this.listCustomer.splice(0, this.listCustomer.length);
      this.listCustomer.push(...data);
      if (
        this.$route.query.customerId != undefined &&
        this.$route.query.customerId != 0
      ) {
        this.customerId = Number(this.$route.query.customerId);
        i++;
      } else {
        this.customerId = this.listCustomer[0].customerId;
        i++;
      }
      if (this.$route.query.fromDate != undefined) {
        this.fromDateValue = this.formatDate(this.$route.query.fromDate);
        i++;
      }
      if (this.$route.query.toDate != undefined) {
        this.toDateValue = this.formatDate(this.$route.query.toDate);
        i++;
      }
      if (i == 1 || i == 3) {
        this.searchVoucherList();
      }
    });
  },
  methods: {
    searchVoucherList: function() {
      this.searchHistory(
        this.fromDateValue,
        this.toDateValue,
        this.customerId,
        this.dateType,
        this.voucherType
      );
      this.voucherList.splice(0, this.voucherList.length);
      var responseVar = [];
      if (this.voucherType == "Voucher") {
        voucherService
          .getPaymentReceiveListByVoucherMaster({
            fromDate: this.formatDateUrl(this.fromDateValue),
            toDate: this.formatDateUrl(this.toDateValue),
            customerId: this.customerId,
            type: this.dateType,
            voucherType: this.voucherType
          })
          .then(response => {
            responseVar = response;
          });
      } else if (this.voucherType == "Change Voucher") {
        voucherService
          .getPaymentReceiveListByVoucherChange({
            fromDate: this.formatDateUrl(this.fromDateValue),
            toDate: this.formatDateUrl(this.toDateValue),
            customerId: this.customerId,
            type: this.dateType,
            voucherType: this.voucherType
          })
          .then(response => {
            responseVar = response;
          });
      }
      if (responseVar.length != 0) {
        var usdAmount = 0;
        var mmkAmount = 0;
        var usdCommission = 0;
        var mmkCommission = 0;
        var usdDiscount = 0;
        var mmkDiscount = 0;
        var usdBalance = 0;
        var mmkBalance = 0;
        var usdReceive = 0;
        var mmkReceive = 0;
        responseVar.forEach(detail => {
          usdAmount += detail.usdAmount;
          mmkAmount += detail.mmkAmount;
          usdCommission += detail.usdCommission;
          mmkCommission += detail.mmkCommission;
          usdDiscount += detail.usdDiscount;
          mmkDiscount += detail.mmkDiscount;
          usdBalance += detail.usdBalance;
          mmkBalance += detail.mmkBalance;
          usdReceive += detail.usdReceive;
          mmkReceive += detail.mmkReceive;
        });
        responseVar.push({
          code: "total",
          usdAmount: usdAmount,
          mmkAmount: mmkAmount,
          usdDiscount: usdDiscount,
          mmkDiscount: mmkDiscount,
          usdCommission: usdCommission,
          mmkCommission: mmkCommission,
          usdBalance: usdBalance,
          mmkBalance: mmkBalance,
          usdReceive: usdReceive,
          mmkReceive: mmkReceive
        });
        this.voucherList.push(...responseVar);
      }
    },
    searchHistory: function(fromDate, toDate, customerId, type, voucherType) {
      let query = {
        fromDate: fromDate,
        toDate: toDate,
        customerId: customerId,
        type: type,
        voucherType: voucherType
      };
      this.$router
        .replace({
          path: "/receiveVoucher",
          query
        })
        .catch(() => {});
    },
    unselected: function(chip) {
      this.selected.splice(this.selected.indexOf(chip), 1);
    },
    receivedVoucher: function() {
      voucherService.receivedVoucher(this.selectedVoucher).then(() => {
        this.searchVoucherList();
        this.checkAllOrZero = false;
        // auto clear in watch
        // this.selectedVoucher.splice(0,this.selectedVoucher.length);
      });
    },
    formatDateUrl: function(date) {
      //if (!date) return null;
      if (this.dateType == "month") {
        const [month, year] = date.split("-");
        return `01-${month}-${year}`;
      } else if (this.dateType == "year") {
        const [year] = date.split("-");
        return `01-01-${year}`;
      }
      return date;
    },
    formatDate: function(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      if (this.dateType == "date") {
        // const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      } else if (this.dateType == "month") {
        // const [year, month, day] = date.split("-");
        return `${month}-${year}`;
      } else {
        // const [year, month, day] = date.split("-");
        return `${year}`;
      }
    }
  },
  computed: {
    toDateValue: {
      get: function() {
        var toDatetemp = "";
        toDatetemp = this.formatDate(this.toDate);
        return toDatetemp;
      },
      set: function(value) {
        this.toDate = value;
      }
    },
    fromDateValue: {
      get: function() {
        var fromDatetemp = "";
        fromDatetemp = this.formatDate(this.fromDate);
        return fromDatetemp;
      },
      set: function(value) {
        this.fromDate = value;
      }
    }
  },
  watch: {
    checkAllOrZero: function(value) {
      if (value) {
        this.voucherList.forEach(list => {
          if (list.code != "total") {
            this.selectedVoucher.push(list.code);
          }
        });
      } else {
        this.selectedVoucher.splice(0, this.selectedVoucher.length);
      }
    }
  }
};
</script>
<style scoped>
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #ffffff;
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.12);
}
.theme--light.v-data-table tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
tr td {
  padding: 0px 3px 0px 3px !important;
  font-size: 12px;
  height: 10px;
}
tr th {
  font-size: 12px;
  padding: 0px 2px 0px 2px !important;
  font-weight: 900;
}
</style>